<template>
  <div class="mobile_menu" v-loading="loading">
    <div class="globle_border">
      <div class="search">
        <el-input v-model="search" placeholder="请输入菜单名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增菜单</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" row-key="menuId" max-height="540" :tree-props="{ children: 'children' }">
          <el-table-column prop="menuName" label="菜单名称"></el-table-column>
          <el-table-column prop="menuOrder" label="排序" width="100"></el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ scope.row.status == 1 ? "正常" : "停用" }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="menuPath" label="路由地址"></el-table-column>
          <el-table-column prop="menuStr" label="权限标识"></el-table-column>
          <el-table-column label="菜单图标" width="100">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.menuLog)">预览图片</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="260">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleAddRow(scope.$index, scope.row)">新增</el-button>
              <el-button class="btn" type="" size="small" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog :title="isAdd ? '新增菜单' : '修改菜单'" :visible.sync="dialogVisible" :before-close="onCancel">
      <!-- 添加 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
        <el-row>
          <el-col :span="12" v-if="isAdd">
            <el-form-item label="上级菜单" prop="menuId">
              <el-cascader class="cascader" ref="menuId" v-model="ruleForm.menuId" :options="tableData" :props="propsOpt" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else>
            <el-form-item label="上级菜单" prop="parentId">
              <el-cascader class="cascader" ref="parentId" v-model="ruleForm.parentId" :options="tableData" :props="propsOpt" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="ruleForm.menuType">
                <el-radio label="0">菜单</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="menuName">
              <el-input v-model="ruleForm.menuName" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
            <el-form-item label="权限标识" prop="menuStr">
              <el-input v-model="ruleForm.menuStr" placeholder="请输入权限标识"></el-input>
            </el-form-item>
            <el-form-item label="菜单图标" prop="menuLog">
              <el-upload class="avatar-uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action" :headers="headers" :show-file-list="false" :on-success="handleSuccess" :on-remove="handleRemove" :before-upload="beforeUpload">
                <img v-if="ruleForm.menuLog" :src="ruleForm.menuLogUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单排序" prop="menuOrder">
              <el-input-number v-model="ruleForm.menuOrder" controls-position="right" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="路由地址" prop="menuPath">
              <el-input v-model="ruleForm.menuPath" placeholder="请输入路由地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
function createdForm() {
  return {
    menuType: "0",
    menuId: "0",
    menuName: "",
    menuPath: "",
    menuOrder: "1",
    menuStr: "",
    menuLog: "",
    menuLogUrl: "",
  }
}
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      id: "",
      loading: false,
      submitLoding: false,
      search: "",
      tableData: [],
      dialogVisible: false,
      isAdd: true,
      ruleForm: createdForm(),
      rules: {
        menuType: [{ required: true, message: "请选择菜单类型", trigger: "submit" }],
        menuName: [{ required: true, message: "请输入菜单名称", trigger: "submit" }],
        menuOrder: [{ required: true, message: "请输入菜单排序", trigger: "submit" }],
        menuPath: [{ required: true, message: "请输入路由地址", trigger: "submit" }],
        menuStr: [{ required: true, message: "请输入权限标识", trigger: "submit" }],
        menuLog: [{ required: true, message: "请选择菜单图标", trigger: "submit" }],
      },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      headers: { token: this.$store.state.token },
      propsOpt: { expandTrigger: "hover", value: "menuId", checkStrictly: true, label: "menuName" },
      // 图片预览
      showViewer: false,
      imgList: [],
    }
  },
  created() {
    this.getSysMenuList()
  },
  methods: {
    // 菜单列表
    getSysMenuList() {
      this.loading = true
      this.$axios
        .get(this.$api.getSysMenuList, {
          params: {
            menuName: this.search,
            menuList: "1", // 0：PC端菜单,1：移动端菜单
          },
        })
        .then(res => {
          this.tableData = res.data.result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.getSysMenuList()
    },
    // 新增菜单
    handleAdd() {
      this.isAdd = true
      this.ruleForm = createdForm()
      this.dialogVisible = true
    },
    // 新增指定子菜单
    handleAddRow(index, row) {
      this.isAdd = true
      this.ruleForm = createdForm()
      this.ruleForm.menuId = row.menuId
      this.ruleForm.menuPath = row.menuPath
      this.dialogVisible = true
    },
    // 修改菜单
    handleEdit(index, row) {
      this.isAdd = false
      this.id = row.menuId
      this.ruleForm = Object.assign({}, row)
      this.dialogVisible = true
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios.put(`${this.$api.deleteSysMenu}/${row.menuId}`).then(res => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getSysMenuList()
          })
        })
        .catch(() => {})
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
      this.submitLoding = false
      this.resetForm()
    },
    // 切换上级菜单
    changeCascader() {
      if (this.isAdd) {
        this.ruleForm.menuId = this.$refs["menuId"].getCheckedNodes()[0].value
      } else {
        this.ruleForm.parentId = this.$refs["parentId"].getCheckedNodes()[0].value
      }
    },
    // 删除文件
    handleRemove() {
      this.ruleForm.menuLog = ""
    },
    // 文件上传成功
    handleSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.menuLog = res.result.fileId
        this.ruleForm.menuLogUrl = res.result.fileUrl
      } else {
        this.$message.error(res.desc)
      }
    },
    // 文件上传
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true
      let params = Object.assign({}, this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          let api = ""
          let modes = ""
          if (this.isAdd) {
            api = this.$api.addSysMenu
            modes = "post"
            params.menuList = "1" // 0：PC端菜单,1：移动端菜单
            params.menuId = null
            params.parentId = this.ruleForm.menuId
          } else {
            api = this.$api.updateSysMenu
            modes = "put"
          }
          this.$axios[modes](api, params).then(res => {
            this.$message.success(res.data.desc)
            this.getSysMenuList()
            this.submitLoding = false
            this.dialogVisible = false
            this.resetForm()
          })
        } else {
          this.$message.error("提交失败")
          this.submitLoding = false
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      if (this.isAdd) {
        this.ruleForm = createdForm()
        this.$refs.ruleForm.resetFields()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.mobile_menu {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  /deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
  }

  /deep/ .el-radio__label {
    font-size: 16px;
  }

  /deep/.el-input-number {
    width: 255px;
  }

  .avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader /deep/ .el-upload:hover {
    border-color: #069bbc;
  }

  .avatar-uploader-icon {
    font-size: 20px;
    color: #069bbc;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  .cascader {
    width: 255px;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 255px;
    }
  }
}
</style>
